import { 
    BasicGet,
    BasicPostWithResult,
 } from './SimpleFetchCall';

import { 
    BannerMessage,
    TenantUserMetadataSetting,
    PortalTextLogin,
    PortalTextSectionIds,
    PortalTextResponse,
} from '../Models/Api/strongbox.financialportal';

import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';
import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';


export async function GetTenantEmailDomains(): Promise<string[]> {
    return await BasicGet<string[]>(
        `/api/tenant/emaildomains`,
        'GetTenantEmailDomains'
    );
}

export async function GetTenantUserMetadataSettings(): Promise<TenantUserMetadataSetting[]> {
    return await BasicGet<TenantUserMetadataSetting[]>(
        `/api/tenant/usermetadatasettings`,
        'GetTenantUserMetadataSettings'
    );
}

export async function GetTenantBannerMessages(): Promise<BannerMessage[]> {
    return await BasicGet<BannerMessage[]>(
        `/api/tenant/bannermessages`,
        'GetTenantBannerMessages'
    );
}

export async function GetTenantTextContent(sectionIds: PortalTextSectionIds[], languageCode: string, localeCode?: string): Promise<PortalTextResponse> {
    const body = {
        languageCode, 
        locale: localeCode,
        sectionIds
    }

    return await BasicPostWithResult<PortalTextResponse>(
        '/api/tenant/portaltext',
        'RetrieveTenantPortalText',
        body
    );
}

export async function GetTenantLoginTextContent(languageCode: string, localeCode?: string): Promise<PortalTextLogin> {
    // needs to operate anonymous so needs to modify second parameter to fetchWrapper and can't use the basic calls
    const nameForLog = 'GetTenantLoginTextContent';
    LogMessage(
        nameForLog,
        SeverityLevel.Information
    );

    const url = `/api/tenant/portallogintext`;

    const body = {
        'languageCode': languageCode,
        'locale': localeCode ?? '',
    }

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(body),
            },
            true,
            false
        );

        if (res.ok) {
            const result: PortalTextLogin = await res.json();
            return result;
        }

        LogException(
            `${nameForLog} failed`,
            new Error(res.statusText)
        );

        throw new Error(`Unable to invoke ${nameForLog} - ${res.statusText}`);
    } catch (exception) {
        LogException(
            `${nameForLog} failed`,
            exception
        );

        console.error(`Exception executing ${nameForLog}`);
        console.error(exception);

        throw exception;
    }
}