import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import * as AppSettings from './AppSettings';
import * as Borrower from './Borrower';
import * as NewDataCollectionParameters from './NewDataCollectionParameters';
import * as Tenant from './Tenant';
import * as UIState from './UIState';
import * as RunningImportsState from './RunningImports';
import * as UserState from './User';
import * as UserRoles from './UserRoles';
import * as UserSettingsState from './UserSettings';
import * as SubmissionHistory from './SubmissionHistory';
import * as Workspaces from './Workspaces';
import * as ImportFinancials from './ImportFinancials';
import * as GlobalSettings from './GlobalSettings';
import * as FinancialStatements from './FinancialStatements';
import * as Submission from './Submission';
import * as PortalAssets from './PortalAssets';

// The top-level state object
export interface ApplicationState {
    appSettings: AppSettings.IAppSettingsState;
    borrower: Borrower.IBorrowerState;
    newDataCollectionParameters: NewDataCollectionParameters.INewDataCollectionParametersState;
    tenant: Tenant.ITenantState;
    uiState: UIState.IUIState;
    runningImportsState: RunningImportsState.IRunningImportsState;
    userState: UserState.IUserState;
    userSettingsState: UserSettingsState.IUserSettingsState;
    userRolesState: UserRoles.IUserRolesState;
    submissionHistory: SubmissionHistory.ISubmissionHistoryState;
    workspaces: Workspaces.IWorkspacesState;
    importFinancials: ImportFinancials.IImportFinancialsState;
    globalSettings: GlobalSettings.IGlobalSettingsState;
    financialStatements: FinancialStatements.IFinancialStatementsState;
    submission: Submission.ISubmissionState;
    portalAssets: PortalAssets.IPortalAssetsState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    appSettings: AppSettings.reducer,
    borrower: Borrower.reducer,
    newDataCollectionParameters: NewDataCollectionParameters.reducer,
    tenant: Tenant.reducer,
    uiState: UIState.reducer,
    runningImportsState: RunningImportsState.reducer,
    userState: UserState.reducer,
    userSettingsState: UserSettingsState.reducer,
    userRolesState: UserRoles.reducer,
    submissionHistory: SubmissionHistory.reducer,
    workspaces: Workspaces.reducer,
    importFinancials: ImportFinancials.reducer,
    globalSettings: GlobalSettings.reducer,
    financialStatements: FinancialStatements.reducer,
    submission: Submission.reducer,
    portalAssets: PortalAssets.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<
    R, // Return type of the thunk function
    E, // Any extra information injected into the thunk
    A extends Action // known type sof actions that can be dispatched.
> = (dispatch: ThunkDispatch<ApplicationState, E, A>, getState: () => ApplicationState, extraArgument: E) => Promise<R>
