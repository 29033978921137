import * as React from 'react';

import '../Main.scss';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import HexImage from '../../images/grey-hex-noborder.png';
import StrongboxLock from '../../images/StrongboxLock.svg';

import {PortalTextLogin} from '../../Models/Api/strongbox.financialportal';
import { AnimatedEllipsis } from '../AnimatedEllipsis/AnimatedEllipsis';

type Props = {
    loginText?: PortalTextLogin;  // It's possible this hasn't been retrieved yet.
};

export const StrongboxLogo: React.FC<Props> = (props): React.ReactElement => {
    const {
        loginText,
    } = props;

    return (
        <Container fluid>
            <Row>
                <Col className={'welcome-content-container-column '}>
                    <span className={'welcome-image'}>
                        <img
                            src={HexImage}
                            className={'hex-background'}
                            alt={'hexagon background'}
                        />
                        <img
                            src={StrongboxLock}
                            className={'strongbox-logo'}
                            alt={'strongbox logo'}
                        />
                    </span>
                    <div className={'brand-text-container'}>
                        {!!loginText && (
                            <React.Fragment>
                                <span>{loginText.loginGreeting1}</span>
                                <div className={'separator-line'} />
                                <span>{loginText.loginGreeting2}</span>
                            </React.Fragment>
                        )}
                        {!loginText && (
                            <div className={'ellipses-container'}>
                                <AnimatedEllipsis />
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
