import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import './Main.scss';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import {
    GetBackgroundImageUrl,
    GetBasicSettings,
    GetTenantName,
    IsLenderDisabled,
    RetrieveStyle,
    TenantBasicSettings,
    TenantLoginDestination,
    TenantLoginText,
    MultiTenantSettings,
    TenantActionCreators,
} from '../Store/Tenant';
import {
    GetPortalLoginText,
    RetrieveLoginText,
} from '../Store/PortalAssets';
import { GetRedirectAfterLogin, UIRedirectInfo } from '../Store/UIState';
import { ApplicationState } from '../Store';

import LoginButton from './Login/LoginButton';
import { MultiTenantLoginButtonContainer } from './Login/MultiTenantLoginButtonContainer';
import { MultiTenantLoginListContainer } from './Login/MultiTenantLoginListContainer';

import { StrongboxLogo } from './Utils/StrongboxLogo';

import { ExtractUrlParameters } from '../Utils/PathUtils';
import { pathConstants } from '../Utils/Constants';

import { LogException, LogMessage, SeverityLevel } from '../Utils/Logging';

import { PortalTextLogin } from '../Models/Api/strongbox.financialportal';

type InjectedReduxState = {
    h1Style: Object;
    tenantName: string;
    redirect?: UIRedirectInfo;
    tenantBasicSettings?: TenantBasicSettings;
    backgroundImage?: string;
    isTenantDisabled: boolean;
    portalLoginText?: PortalTextLogin;
};

type InjectedActionCreators = typeof TenantActionCreators;

type LoginProps = {
};

type Props = LoginProps & InjectedReduxState & InjectedActionCreators;

const LoginComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        portalLoginText,
    } = props;

    const location = useLocation();

    const teamDelimiter = '<team>'

    const defaultWelcomeTextLine1: TenantLoginText = {
        bold: true,
        bottomMargin: 135,
        content: ``,
        fontSize: 20

    };

    const defaultWelcomeText = [defaultWelcomeTextLine1];

    const [inactivityTimeout, setInactivityTimeout] = React.useState<boolean>(false);
    const [loginThisPortalName, setLoginThisPortalName] = React.useState<string>('');
    const [loginContainer, setLoginContainer] = React.useState<React.ReactElement | undefined>(undefined);
    const [welcomeText, setWelcomeText] = React.useState<TenantLoginText[]>(defaultWelcomeText);
    const [showLoginButton, setShowLoginButton] = React.useState<boolean>(true);
    // navToLogin will be set when we arrive at the login page with the 
    // pathConstants.autoNavToLogin ('autologin') present in the URL and
    // set to true.  In that case we'll just render the <LoginButton and it
    // in turn will just execute the Auth0 login sequence for this portal.
    // That happens in a multi-tenant scenario where they opt to go straight
    // from one portal to a different portal and immediately login.
    const [navToLogin, setNavToLogin] = React.useState<boolean>(false);
    const [separatorAfterWelcome, setSeparatorAfterWelcome] = React.useState<boolean>(false);
    const [placeButtonAfterLine, setPlaceButtonAfterLine] = React.useState<number>(1);

    const resetDefaultLoginSettings = (): void => {
        setLoginThisPortalName('');
        setLoginContainer(undefined);
        setShowLoginButton(true);
        setPlaceButtonAfterLine(1);
        setSeparatorAfterWelcome(false);
        setNavToLogin(false);
        setWelcomeText(defaultWelcomeText);
    }

    React.useEffect(() => {
        let inactivity = false;

        RetrieveLoginText();
    
        ExtractUrlParameters(
            location.search,
            (key: string, value: string): void => {
                switch (key.toLowerCase()) {
                    case pathConstants.queryParamInactivityTimeout:
                        if (value.toLowerCase() === 'true') {
                            inactivity = true;
                        }
                        break;
                    case pathConstants.autoNavToLogin: {
                        const setting = value.toLowerCase() === 'true';
                        setNavToLogin(setting);
                        break;
                    }
                }
            }
        );

        setInactivityTimeout(inactivity);
        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const executeLogin = (url: string, autoProcessLogin: boolean): void => {
        window.location.href = `${url}?redirected=true&${pathConstants.autoNavToLogin}=${autoProcessLogin}`
    }

    const setLoginDestinations = (loginDestinations: TenantLoginDestination[], includeThisPortalInButtons: boolean, autoProcessLogin: boolean): void => {
        const thisPortal = loginDestinations.find(dest => dest.tenantName === props.tenantName);
        if (!!thisPortal) {
            setLoginThisPortalName(thisPortal.name);
        }

        // Nothing particularly special about 3, just seems to work well aesthetically.
        if (loginDestinations.length > 3) {
            setLoginContainer((
                <MultiTenantLoginListContainer
                    loginDestinations={loginDestinations}
                    thisPortal={thisPortal}
                    includeThisPortal={includeThisPortalInButtons}
                    executeLogin={(url) => executeLogin(url, autoProcessLogin)}
                />
            ));
        } else {
            setLoginContainer((
                <MultiTenantLoginButtonContainer
                    loginDestinations={loginDestinations}
                    thisPortal={thisPortal}
                    includeThisPortal={includeThisPortalInButtons}
                    executeLogin={(url) => executeLogin(url, autoProcessLogin)}
                />
            ));
        }
    }

    const setMultiTenant = (multiTenantSettings: MultiTenantSettings): void => {
        setLoginDestinations(
            multiTenantSettings.loginDestinations,
            multiTenantSettings.defaultLogin === false,
            multiTenantSettings.passLoginThrough === true
        );
        if (!!multiTenantSettings.welcomeText) {
            setWelcomeText(multiTenantSettings.welcomeText);
        } else {
            setWelcomeText(defaultWelcomeText);
        }
        setShowLoginButton(multiTenantSettings.defaultLogin !== false);

        setSeparatorAfterWelcome(
            multiTenantSettings.showSeparatorAfterWelcomeText !== undefined ?
                multiTenantSettings.showSeparatorAfterWelcomeText : false
        )

        setPlaceButtonAfterLine(
            multiTenantSettings.placeLoginAfterWelcomeLine !== undefined ?
                multiTenantSettings.placeLoginAfterWelcomeLine : 1
        );
    }

    React.useEffect(() => {
        if (!!props.tenantBasicSettings) {
            if (!!props.tenantBasicSettings.multiTenantSettings) {
                // We have to error out if both passLoginThrough and defaultLogin
                // are false.  Otherwise you have no default login button on the destination
                // page and you don't pass through to auto-login.  Basically you can never
                // login.  There are more complex ways to handle this but probably 
                // overkill for what we're trying to accomplish here on something that's
                // going to be configured by us and should be relatively obvious that it's 
                // failing if we put in an invalid configuration.
                if (!(props.tenantBasicSettings.multiTenantSettings.passLoginThrough ||
                    props.tenantBasicSettings.multiTenantSettings.defaultLogin)) {
                    const msg = 'Invalid configuration, passLoginThrough and defaultLogin cannot both be false';

                    LogMessage(msg, SeverityLevel.Error);
                    console.error(msg);

                    resetDefaultLoginSettings();
                } else {
                    try {
                        setMultiTenant(props.tenantBasicSettings.multiTenantSettings);
                    } catch (exception) {
                        const msg = 'Exception caught processing config.json file. Resetting to defaults';

                        LogException(
                            msg,
                            exception
                        );
                        console.error(msg);

                        resetDefaultLoginSettings();
                    }
                }
            } else {
                resetDefaultLoginSettings();
            }
        } else {
            resetDefaultLoginSettings();
        }
        // tenantBasicSettings never actually changes in one session so this does not need to be in the dependency list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tenantName, props.tenantBasicSettings]);

    const rowContent = React.useMemo(() => {
        const result: React.ReactElement[] = [];

        welcomeText.forEach((pg, pgIndex) => {
            const textContent = pg.content;
            const stringComponents = textContent.split(teamDelimiter);
            let pgStyle: React.CSSProperties = {};

            if (!!pg.bold) {
                pgStyle['fontWeight'] = 'bold';
            }
            if (!!pg.fontSize) {
                pgStyle['fontSize'] = `${pg.fontSize}px`;
            }
            if (!!pg.bottomMargin) {
                pgStyle['marginBottom'] = `${pg.bottomMargin}px`;
            }
            // Guaranteed to have at least one element in stringComponents
            const pgElement = (
                <p
                    className={'login-text'}
                    style={pgStyle}
                    key={`tenant-welcome-paragraph-${pgIndex}`}
                >
                    {stringComponents[0]}
                    {stringComponents.map((component, index) => {
                        if (index === 0) {
                            return (<React.Fragment key={`tenant-welcome-paragraph-${pgIndex}-${index}`}></React.Fragment>);
                        } else {
                            return (
                                <React.Fragment key={`tenant-welcome-paragraph-${pgIndex}-${index}`}><b>{` ${loginThisPortalName}`}</b>{` ${component}`}</React.Fragment>
                            );
                        }
                    })}
                </p>
            )

            result.push(pgElement);

            if (showLoginButton && ((pgIndex + 1) === placeButtonAfterLine)) {
                result.push((
                    <LoginButton
                        redirectInfo={props.redirect}
                        autoNavToLogin={false}
                        style={{ 'marginBottom': '50px' }}
                        buttonText={portalLoginText?.loginButton}
                    />
                ));
            }
        });

        if (separatorAfterWelcome) {
            result.push(
                <div className={'separator-line'} />
            )
        }

        return result;

        // These are the actual dependencies I want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [welcomeText, loginThisPortalName, portalLoginText]);

    const backgroundStyle = React.useMemo(() => {
        return {
            backgroundImage: `url('${props.backgroundImage}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'

        }
    }, [props.backgroundImage]);

    if (props.isTenantDisabled) {
        return <Navigate to={pathConstants.tenantLenderDisabled} />
    }
    if (inactivityTimeout) {
        return <Navigate to={pathConstants.slidingWindowTimeout} />
    }

    return (
        <div
            className={`full-screen-region lender-region content-region control-region control-region-lender`}
            style={backgroundStyle}
        >
            {navToLogin && (
                <LoginButton
                    redirectInfo={props.redirect}
                    autoNavToLogin={true}
                />
            )}

            {!navToLogin && (
                <Container className={'login-content-container strongbox-basic-content-container'}>
                    <Row>
                        <Col>
                            <StrongboxLogo 
                                loginText={portalLoginText}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '40px' }}>
                        <Col>
                            {rowContent.map((pg, pgIndex) => (
                                <Row key={`tenant-welcome-paragraph-row-${pgIndex}`}>
                                    <Col className={'col-centered'}>
                                        {pg}
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    {!!loginThisPortalName && !!loginContainer && (
                            <Row style={{ marginTop: '15px' }}>
                                <Col className={'col-centered'}>
                                    {loginContainer}
                                </Col>
                            </Row>
                    )}
                </Container>
            )}
        </div>
    );
}

export const Login = connect<InjectedReduxState, InjectedActionCreators, LoginProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            backgroundImage: GetBackgroundImageUrl(appState),
            h1Style: RetrieveStyle(appState, 'h1-login'),
            tenantName: GetTenantName(appState),
            redirect: GetRedirectAfterLogin(appState),
            tenantBasicSettings: GetBasicSettings(appState),
            isTenantDisabled: IsLenderDisabled(appState),
            portalLoginText: GetPortalLoginText(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...TenantActionCreators,
        },
        dispatch
    )
)(LoginComponent);
