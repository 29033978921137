import { Reducer } from 'redux';

import { KnownAction, PortalAssetsActions } from './Actions';

import {
    PortalText,
    PortalTextSectionIds,
} from '../../Models/Api/strongbox.financialportal';

export type PortalTextSectionRoot = {
    id: PortalTextSectionIds;
}

export interface IPortalAssetsState {
    // Maps section ids to the values retrieved. In c# land, everything stored in
    // this map will be descended from a 'TextSection' which at the root level
    // contains nothing more than an id.  So stuff stored here will all be guaranteed
    // to have an id. Type checking on the back end form source JSON files will ensure
    // that the actual type stored for a secion id will be the correct type.
    textSections: Map<PortalTextSectionIds, PortalTextSectionRoot>;
    languageCode: string;   // something like "en"
    localeCode: string;     // something like "us"
}

const defaultState: IPortalAssetsState = {
    textSections: new Map<PortalTextSectionIds, PortalTextSectionRoot>(),
    languageCode: 'en',
    localeCode: 'us',
}

function MergePortalText(state: IPortalAssetsState, sectionIds: PortalTextSectionIds[], portalText: PortalText): IPortalAssetsState {
    if (!!state.textSections) {
        state.textSections = new Map<PortalTextSectionIds, PortalTextSectionRoot>(state.textSections);
    } else {
        state.textSections = new Map<PortalTextSectionIds, PortalTextSectionRoot>();
    }
    sectionIds.forEach((sectionId) => {
        const indexSectionId = Object.keys(portalText).findIndex((portalTextSectionId) => {
            return sectionId === portalTextSectionId;
        });
        if (indexSectionId !== -1) {
            state.textSections.set(sectionId, Object.values(portalText)[indexSectionId]);
        }
    });

    return state;
}

export const reducer: Reducer<IPortalAssetsState, KnownAction> = (state: IPortalAssetsState | undefined, action: KnownAction): IPortalAssetsState => {
    let newState: IPortalAssetsState | undefined = undefined;

    switch (action.type) {
        case PortalAssetsActions.PortalAssetsInitialize: {
            newState = {
                ...(state || defaultState),
            }
            const lang = navigator.language;
            if (!!lang) {
                const langParts = lang.split('-');
                newState.languageCode = langParts[0].toLowerCase();
                if (langParts.length > 1) {
                    newState.localeCode = langParts[1].toLowerCase();
                }
            }
            break;
        }
        case PortalAssetsActions.PortalAssetsRetrieveLoginText: {
            // nothing to do here yet.
            break;
        }
        case PortalAssetsActions.PortalAssetsRetrieveLoginTextCompleted: {
            if (!!action.portalLoginText)
            {
                newState = {
                    ...(state || defaultState),
                }
                if (!!newState.textSections) {
                    newState.textSections = new Map<PortalTextSectionIds, PortalTextSectionRoot>(newState.textSections);
                } else {
                    newState.textSections = new Map<PortalTextSectionIds, PortalTextSectionRoot>();
                }
                newState.textSections.set('loginPage', action.portalLoginText);
            }
            break;
        }
        case PortalAssetsActions.PortalAssetsRetrieveText: {
            // nothing to do here yet.
            break;
        }
        case PortalAssetsActions.PortalAssetsRetrieveTextCompleted: {
            if (!!action.portalTextResponse) {
                newState = {
                    ...(state || defaultState),
                }
                newState = MergePortalText(newState, action.sectionIds, action.portalTextResponse.content);
            }
            break;
        }
    }
    return newState ? newState : state ? state : defaultState;
}
