import { store } from '../../index';

import { 
    PortalAssetsActions,
    IPortalAssetsInitialize,
} from './Actions';

import { 
    PortalTextSectionIds,
} from '../../Models/Api/strongbox.financialportal';

import { 
    GetTenantLoginTextContent,
    GetTenantTextContent,
} from '../../Services/Tenant';

import { LogException } from '../../Utils/Logging';

function PortalAssetsInitialize(): IPortalAssetsInitialize {
    return {
        type: PortalAssetsActions.PortalAssetsInitialize,
    };
}

function GetLanguageAndLocale(): { languageCode: string, locale: string } {
    const appState = store.getState();

    let languageCode = 'en';
    let locale = 'us';

    if (!!appState && !!appState.portalAssets) {
        languageCode = appState.portalAssets.languageCode;
        locale = appState.portalAssets.localeCode;
    }

    return {
        languageCode,
        locale,
    };
}

export async function RetrieveLoginText(): Promise<void> {
    store.dispatch({
        type: PortalAssetsActions.PortalAssetsRetrieveLoginText
    });

    try {
        const languageAndLocale = GetLanguageAndLocale();

        const result = await GetTenantLoginTextContent(languageAndLocale.languageCode, languageAndLocale.locale);

        store.dispatch({
            type: PortalAssetsActions.PortalAssetsRetrieveLoginTextCompleted,
            portalLoginText: result,
        });

    } catch (ex) {
        const errMsg = "Failure retrieving portal login text";

        LogException(errMsg, ex);
        console.error(errMsg);
        console.error(ex);

        store.dispatch({
            type: PortalAssetsActions.PortalAssetsRetrieveLoginTextCompleted,
        });
    }
}

function PruneCollectedSections(sectionIds: PortalTextSectionIds[]): PortalTextSectionIds[] {
    const appState = store.getState();
    if (!(appState && appState.portalAssets)) {
        return sectionIds;
    }
    const result: PortalTextSectionIds[] = [];

    sectionIds.forEach(sectionId => {
        const existing = appState.portalAssets.textSections.get(sectionId);
        if (!existing) {
            result.push(sectionId);
        }
    });
    return result;
}

export async function RetrieveText(sectionIds: PortalTextSectionIds[], retrieveCollectedSections?: boolean): Promise<void> {
    let sectionIdsToUse = sectionIds.slice();

    try {
        const languageAndLocale = GetLanguageAndLocale();

        if (!retrieveCollectedSections) {
            sectionIdsToUse = PruneCollectedSections(sectionIdsToUse);
            if (sectionIdsToUse.length <= 0) {
                return;
            }
        }

        store.dispatch({
            type: PortalAssetsActions.PortalAssetsRetrieveText,
        });
    
        const result = await GetTenantTextContent(
            sectionIdsToUse,
            languageAndLocale.languageCode, 
            languageAndLocale.locale
        );

        store.dispatch({
            type: PortalAssetsActions.PortalAssetsRetrieveTextCompleted,
            portalTextResponse: result,
            sectionIds: sectionIdsToUse,
        });

    } catch (ex) {
        const errMsg = "Failure retrieving portal text";

        LogException(errMsg, ex);
        console.error(errMsg);
        console.error(ex);

        store.dispatch({
            type: PortalAssetsActions.PortalAssetsRetrieveTextCompleted,
            sectionIds: sectionIdsToUse,
        });
    }
}

export const PortalAssetsActionCreators = {
    PortalAssetsInitialize,
};

