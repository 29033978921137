import { TenantPalette } from './Actions';
import { ApplicationState } from '../index';
import {
    KnownTenants,
    ResourceName,
    TenantBasicSettings,
    TenantFlowDisabledContent,
    TenantLoginText,
} from './Actions';
import { BrandConfig } from './Reducer';

import { 
    BannerMessage, 
    TenantUserMetadataSetting,
} from '../../Models/Api/strongbox.financialportal';

import { assetBlobContainer } from '../../Utils/Constants';

import DefaultBackground from '../../images/DefaultBackground.png';

export function GetTenantName(state: ApplicationState): string {
    return (state && state.tenant && state.tenant.tenantName) || KnownTenants.strongbox;
}

export function GetBasicSettings(state: ApplicationState): TenantBasicSettings | undefined {
    return (state && state.tenant && state.tenant.basicSettings);
}

export function GetBackgroundImageUrl(state: ApplicationState): string {
    const tenant = GetTenantName(state);
    if (!(state && state.tenant && state.tenant.basicSettings)) {
        return DefaultBackground;
    }

    return (!!state.tenant.basicSettings.background) ?
        `${assetBlobContainer}/brandassets/${tenant}/${state.tenant.basicSettings.background}` : DefaultBackground
}

export function GetBrandConfig(state: ApplicationState): BrandConfig {
    return (state && state.tenant && state.tenant.brandConfig);
}

/**
 * append the current tenant name onto a prefix URL and return the result with a trailing '/'.   
 * 
 * @param prefix is the prefix for the url, e.g. '/images/'.  Does not matter if the prefix has
 * a trailing slash or not.
 */

export function BuildTenantUrl(state: ApplicationState, prefix: string): string {
    // replace below will strip the / from the end.

    const temp = prefix.replace(/\/+$/, '');
    if (state && state.tenant && state.tenant.tenantName)
        return `${temp}/${state.tenant.tenantName}/`;
    else
        return `${temp}/${KnownTenants.strongbox}/`;
}

/**
 * Find the branded resource that is identified by resourceName
 * 
 * @param state
 * @param resourceName  The name of the resource.  This would be something like 'background-image'.  The value returned will be
 *  the correct value for the current brand.
 *  
 *  @return The correct branded resource for the resourceName provided or undefined if not found
 * */

export function RetrieveResource(state: ApplicationState, resourceName: string): any {
    if (!(state && state.tenant && state.tenant.resourceLists)) {
        return undefined;
    }

    const rl = state.tenant.resourceLists.find(rl => rl.resourceName === resourceName);

    if (!rl) {
        return undefined;
    }

    const defaultName = (state.tenant.basicSettings && state.tenant.basicSettings.defaultResource) || KnownTenants.defaultLender;

    let resource = rl.resources.find(resource => resource.tenantName === state.tenant.tenantName);
    if (!resource) {
        resource = rl.resources.find(resource => resource.tenantName === defaultName);
    }

    return (resource && resource.resource) || undefined;
}

/**
 * Find the branded style that is identified by styleName
 *
 * @param state
 * @param styleName  The name of the style.  This would be something like 'h1-login' for <h1> on the login page.  
 * The value returned will be the correct style for the current brand or {} if it is not defined.
 *
 *  @return The correct branded style for the styleName provided or {} if not found
 * */

export function RetrieveStyle(state: ApplicationState, styleName: string): any {
    if (!(state && state.tenant && state.tenant.resourceLists)) {
        return {};
    }

    const rl = state.tenant.resourceLists.find(rl => rl.resourceName === ResourceName.stylePalette);
    if (!rl) {
        return undefined;
    }

    const resource = rl.resources.find(resource => resource.tenantName === state.tenant.tenantName);
    if (!resource) {
        return {};
    }
    const palette: TenantPalette = resource.resource as TenantPalette;
    if (!(palette && palette.paletteColletion && palette.paletteColletion[styleName])) {
        return {}
    }

    return palette.paletteColletion[styleName].value || {};
}

export function IsLenderDisabled(state: ApplicationState): boolean {
    return (
        !!state &&
        !!state.tenant &&
        !!state.tenant.basicSettings &&
        !!state.tenant.basicSettings.tenantDisable &&
        !!state.tenant.basicSettings.tenantDisable.lender
    );
}

export function IsBorrowerDisabled(state: ApplicationState): boolean {
    return (
        !!state &&
        !!state.tenant &&
        !!state.tenant.basicSettings &&
        !!state.tenant.basicSettings.tenantDisable &&
        !!state.tenant.basicSettings.tenantDisable.borrower
    );
}

export function GetLenderDisabledContent(state: ApplicationState): TenantFlowDisabledContent | undefined {
    if (!(state && state.tenant && state.tenant.basicSettings && state.tenant.basicSettings.tenantDisable)) {
        return undefined;
    }
    return state.tenant.basicSettings.tenantDisable.lender;
}

export function GetBorrowerDisabledContent(state: ApplicationState): TenantFlowDisabledContent | undefined {
    if (!(state && state.tenant && state.tenant.basicSettings && state.tenant.basicSettings.tenantDisable)) {
        return undefined;
    }
    return state.tenant.basicSettings.tenantDisable.borrower;
}

export function GetTenantLoginTextStyle(loginText: TenantLoginText): React.CSSProperties {
    let style: React.CSSProperties = {}

    if (loginText.bold) {
        style['fontWeight'] = 'bold';
    }
    if (!!loginText.fontSize) {
        style['fontSize'] = `${loginText.fontSize}px`;
    }
    if (!!loginText.bottomMargin) {
        style['marginBottom'] = `${loginText.bottomMargin}px`;
    }
    return style;
}

export function GetEmailDomains(state: ApplicationState): string[] {
    if (!(state && state.tenant)) {
        return [];
    }
    return state.tenant.emailDomains;
}

export function GetUserMetadataSettings(state: ApplicationState): TenantUserMetadataSetting[] {
    if (!(state && state.tenant)) {
        return [];
    }
    return state.tenant.userMetadataSettings;
}

export function GetBannerMessages(state: ApplicationState): BannerMessage[] {
    if (!(state && state.tenant)) {
        return [];
    }
    return state.tenant.bannerMessages;
}
