import { 
    PortalTextResponse,
    PortalTextLogin, 
    PortalTextSectionIds,
} from '../../Models/Api/strongbox.financialportal';

export enum PortalAssetsActions {
    PortalAssetsInitialize = 'PORTALASSETS/INITIALIZE',
    PortalAssetsRetrieveLoginText = 'PORTALASSETS/RETRIEVE_LOGIN_TEXT',
    PortalAssetsRetrieveLoginTextCompleted = 'PORTALASSETS/RETRIEVE_LOGIN_TEXT_COMPLETED',
    PortalAssetsRetrieveText = 'PORTASSETS/RETRIEVE_TEXT',
    PortalAssetsRetrieveTextCompleted = 'PORTASSETS/RETRIEVE_TEXT_COMPLETED',
}

export interface IPortalAssetsInitialize {
    type: PortalAssetsActions.PortalAssetsInitialize;
}

export interface IPortalAssetsRetrieveLoginText {
    type: PortalAssetsActions.PortalAssetsRetrieveLoginText;
}

export interface IPortalAssetsRetrieveTextLoginCompleted {
    type: PortalAssetsActions.PortalAssetsRetrieveLoginTextCompleted;
    portalLoginText?: PortalTextLogin;
}

export interface IPortalAssetsRetrieveText {
    type: PortalAssetsActions.PortalAssetsRetrieveText;
}

export interface IPortalAssetsRetrieveTextCompleted {
    type: PortalAssetsActions.PortalAssetsRetrieveTextCompleted;
    portalTextResponse?: PortalTextResponse;
    sectionIds: PortalTextSectionIds[];
}

export type KnownAction =
    IPortalAssetsInitialize |
    IPortalAssetsRetrieveLoginText |
    IPortalAssetsRetrieveTextLoginCompleted |
    IPortalAssetsRetrieveText |
    IPortalAssetsRetrieveTextCompleted;
