import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';
import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';

export async function BasicGet<T>(url: string, nameForLog: string, logParameters?: any): Promise<T> {
    LogMessage(
        nameForLog,
        SeverityLevel.Information,
        logParameters
    );

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (res.ok) {
            const result: T = await res.json();
            return result;
        }

        LogException(
            `${nameForLog} failed`,
            new Error(res.statusText),
            logParameters
        );

        throw new Error(`Unable to invoke ${nameForLog} - ${res.statusText}`);
    } catch (exception) {
        LogException(
            `${nameForLog} failed`,
            exception,
            logParameters
        );

        console.error(`Exception executing ${nameForLog}`);
        console.error(exception);

        throw exception;
    }
}

export async function BasicPost(url: string, nameForLog: string, body?: any, logParameters?: any): Promise<void> {
    LogMessage(
        nameForLog,
        SeverityLevel.Information,
        logParameters
    );

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(body),
            },
            true,
            true
        );

        if (res.ok) {
            return;
        }

        LogException(
            `${nameForLog} failed`,
            new Error(res.statusText),
            logParameters
        );

        throw new Error(`Error calling ${nameForLog} - ${res.statusText}`);
    } catch (exception) {
        LogException(
            `${nameForLog} failed`,
            exception,
            logParameters
        );

        console.error(`Exception executing ${nameForLog}`);
        console.error(exception);

        throw exception;
    }
}


export async function BasicPostWithResult<T>(url: string, nameForLog: string, body?: any, logParameters?: any): Promise<T> {
    LogMessage(
        nameForLog,
        SeverityLevel.Information,
        logParameters
    );

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(body),
            },
            true,
            true
        );

        if (res.ok) {
            return await res.json();
        }

        LogException(
            `${nameForLog} failed`,
            new Error(res.statusText),
            logParameters
        );

        throw new Error(`Error calling ${nameForLog} - ${res.statusText}`);
    } catch (exception) {
        LogException(
            `${nameForLog} failed`,
            exception,
            logParameters
        );

        console.error(`Exception executing ${nameForLog}`);
        console.error(exception);

        throw exception;
    }
}

