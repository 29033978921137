import { ApplicationState } from '../index';
import {} from './Reducer';
import { 
    PortalTextLogin,
    PortalTextSectionIds,
} from '../../Models/Api/strongbox.financialportal';

export function GetPortalLoginText(state: ApplicationState): PortalTextLogin | undefined {
    if (!(state && state.portalAssets)) {
        return undefined;
    }
    const loginText = state.portalAssets.textSections.get('loginPage');
    if (!loginText) {
        return undefined;
    }

    return loginText as PortalTextLogin;
}

export function GetPortalText<T>(state: ApplicationState, sectionId: PortalTextSectionIds ): T | undefined {
    if (!(state && state.portalAssets)) {
        return undefined;
    }
    const text = state.portalAssets.textSections.get(sectionId);
    if (!text) {
        return undefined;
    }

    return text as T;
}
